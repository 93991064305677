'use client';

import { forwardRef, type ReactNode, useContext } from 'react';

import { cn } from '@/lib/utils';

import ProductCardBrand from './ProductCardBrand';
import { ProductCardContext } from './ProductCardContext';
import ProductCardPrice from './ProductCardPrice';
import ProductCardTitle from './ProductCardTitle';

type ProductCardContentRef = HTMLDivElement;

type ProductCardContentProps = {
    children?: ReactNode;
    className?: string;
};

const ProductCardContentWrapper = forwardRef<
    ProductCardContentRef,
    ProductCardContentProps
>(({ children, className }, ref) => {
    const { isDense } = useContext(ProductCardContext);

    return (
        <div
            ref={ref}
            className={cn(
                'relative text-sm',
                isDense ? 'px-2 pb-3.5 pt-2' : 'px-3 pb-5 pt-3',
                className,
            )}>
            {children}
        </div>
    );
});

const ProductCardContent = forwardRef<
    ProductCardContentRef,
    ProductCardContentProps
>(({ children, ...props }, ref) => {
    if (!children) {
        return (
            <ProductCardContentWrapper ref={ref} {...props}>
                <ProductCardBrand />
                <ProductCardTitle />
                <ProductCardPrice />
            </ProductCardContentWrapper>
        );
    }

    return (
        <ProductCardContentWrapper ref={ref} {...props}>
            {children}
        </ProductCardContentWrapper>
    );
});

ProductCardContent.displayName = 'ProductCardContent';

export default ProductCardContent;

import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

const WishlistIcon = (props: Props) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M11.6407 5.94297L12 6.31411L12.3593 5.94297C14.2216 4.01901 17.2342 4.01901 19.0966 5.94297C20.9678 7.87607 20.9678 11.0168 19.0966 12.9499L12 20.2811L4.90341 12.9499C3.0322 11.0168 3.0322 7.87607 4.90341 5.94297C6.76579 4.01901 9.77836 4.01901 11.6407 5.94297Z"
                stroke="currentColor"
            />
        </svg>
    );
};

WishlistIcon.displayName = 'WishlistIcon';

export default WishlistIcon;

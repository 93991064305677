export const getMatchingCampaigns = (
    campaigns?: {
        slug: string;
        commerceId: string;
        campaignTitle: string;
        campaignBadge: string;
        campaignLabel: string;
        campaignStart: string;
        campaignEnd: string;
        showBadgeForAllProductsInCampaign?: boolean;
    }[],
    productFlags: string[] = [],
) => {
    if (!campaigns?.length) return [];
    if (productFlags.length === 0) return [];

    return campaigns.filter((campaign) =>
        productFlags.includes(campaign.commerceId),
    );
};

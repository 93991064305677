import { identifyImageCDN } from './identifyImageCDN';

/**
 * Determines which image format to use based on the image CDN.
 */
export const getImageFormat = (src: string): string => {
    const imageCDN = identifyImageCDN(src);
    if (imageCDN === 'contentful') {
        return '&fm=avif'; // Contentful CDN uses 'fm' flag and supports avif
    } else if (imageCDN === 'norce') {
        return '&f=webp'; // Norce CDN uses 'f' flag and only supports webp
    }
    return '';
};

'use client';

import { Paragraph } from '@components/ui';
import { useContext } from 'react';

import { cn } from '@/lib/utils';

import { ProductCardContext } from './ProductCardContext';

const ProductCardTitle = () => {
    const { product, isDense } = useContext(ProductCardContext);

    if (!product?.title) return null;

    return (
        <Paragraph
            className={cn('truncate', isDense && 'text-xs')}
            data-testid="ProductCard-title">
            {product.title}
        </Paragraph>
    );
};

ProductCardTitle.displayName = 'ProductCardTitle';

export default ProductCardTitle;

import { getCookie, setCookie } from 'cookies-next';

import { COOKIE_MAX_AGE } from '@/utils/const';

import { uuidv4 } from './uuidv4';

const ANONYMOUS_REQUEST_UID = 'Anonymous-Request-Uid';

export default function getAnonymousRequestUid() {
    let cookie = getCookie(ANONYMOUS_REQUEST_UID);

    if (!cookie) {
        cookie = uuidv4();
        setCookie(ANONYMOUS_REQUEST_UID, cookie, { maxAge: COOKIE_MAX_AGE });
    }

    return String(cookie);
}

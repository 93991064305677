'use client';

import Link from '@components/Link';
import getSearchEngineUserUid from '@server/utils/getSearchEngineUserUid';
import type { ReactNode } from 'react';
import { forwardRef, useCallback, useContext } from 'react';

import { cn } from '@/lib/utils';
import type { Loop54EventEntityType } from '@/utils/createLoopEvent';
import createLoopEvent from '@/utils/createLoopEvent';
import { gTagSelectListItem } from '@/utils/googleTags';

import { ProductCardContext } from './ProductCardContext';

type ProductCardLinkProps = {
    className?: string;
    children?: ReactNode;
};

const ProductCardLink = forwardRef<HTMLAnchorElement, ProductCardLinkProps>(
    ({ className, children, ...props }, ref) => {
        const { product, locale, market, pageData } =
            useContext(ProductCardContext);

        const searchEngineUser = getSearchEngineUserUid();

        const trackSelectListItemEvent = useCallback(() => {
            if (
                gTagSelectListItem == undefined ||
                product === undefined ||
                market?.currency === undefined
            ) {
                return;
            }

            gTagSelectListItem(product, market.currency, pageData);
        }, [market?.currency, product, pageData]);

        const trackLoopEvent = useCallback(() => {
            if (
                createLoopEvent === undefined ||
                product === undefined ||
                market?.code === undefined
            ) {
                return;
            }

            void createLoopEvent({
                marketCode: market.code,
                searchEngineUser,
                payload: {
                    events: [
                        {
                            type: 'click',
                            entity: {
                                type: product.type as Loop54EventEntityType,
                                id: product.id,
                            },
                        },
                    ],
                },
            });
        }, [market?.code, product, searchEngineUser]);

        if (!product) return null;

        return (
            <Link
                ref={ref}
                locale={locale}
                href={product.ProductURL ?? ''}
                className={cn('block bg-white', className)}
                onClick={() => {
                    trackSelectListItemEvent();
                    trackLoopEvent();
                }}
                {...props}>
                {children}
            </Link>
        );
    },
);

ProductCardLink.displayName = 'ProductCardLink';

export default ProductCardLink;

'use client';

import { forwardRef, type ReactNode } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

import { cn } from '@/lib/utils';

type ProductCardBadgeRef = HTMLDivElement;

type ProductCardBadgeProps = VariantProps<typeof badge> & {
    children?: ReactNode;
    className?: string;
};

const badge = tv({
    base: 'absolute z-10 text-xxs uppercase',
    variants: {
        y: {
            start: 'top-0',
            end: 'bottom-0',
        },
        x: {
            start: 'left-0',
            end: 'right-0',
        },
    },
    compoundVariants: [
        {
            y: 'start',
            x: 'start',
            className: 'left-0 top-0',
        },
        {
            y: 'start',
            x: 'end',
            className: 'right-0 top-0',
        },
        {
            y: 'end',
            x: 'start',
            className: 'bottom-0 left-0',
        },
        {
            y: 'end',
            x: 'end',
            className: 'bottom-0 right-0',
        },
    ],
});

const ProductCardBadge = forwardRef<ProductCardBadgeRef, ProductCardBadgeProps>(
    ({ children, className, y, x, ...props }, ref) => (
        <div ref={ref} className={cn(badge({ y, x }), className)} {...props}>
            {children}
        </div>
    ),
);

ProductCardBadge.displayName = 'ProductCardBadge';

export default ProductCardBadge;

'use client';

import { useWishlist } from '@context/wishlistContext';
import { Button } from '@ngg/components';
import { useCallback, useContext, useRef } from 'react';

import { WishlistIcon } from '@/icons/WishlistIcon';
import { cn } from '@/lib/utils';

import { ProductCardContext } from './ProductCardContext';

const DEBOUNCE_DELAY = 200;

type ProductCardFavoriteButtonProps = {
    className?: string;
};

const ProductCardFavoriteButton = ({
    className,
}: ProductCardFavoriteButtonProps) => {
    const { product } = useContext(ProductCardContext);
    const { groupId } = product || {};

    const { isInWishlist, addToWishlist, removeFromWishlist, isFetching } =
        useWishlist();
    const selectedItem = isInWishlist(groupId || '');
    const debounce = useRef<ReturnType<typeof setTimeout>>();

    const toggleWishlist = useCallback(async () => {
        if (!selectedItem) await addToWishlist(groupId || '');
        else if (selectedItem?.id) await removeFromWishlist(selectedItem.id);
    }, [addToWishlist, removeFromWishlist, groupId, selectedItem]);

    const handleWishlistClick = useCallback(
        async (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();

            if (isFetching) return;

            await new Promise((resolve) => {
                if (debounce.current) clearTimeout(debounce.current);
                debounce.current = setTimeout(resolve, DEBOUNCE_DELAY);
            }).then(() => toggleWishlist());
        },
        [toggleWishlist, isFetching],
    );

    return (
        <Button
            type="button"
            className={cn(
                'wishlist-btn flex items-center justify-center px-4 py-3 text-base text-black [&>span]:hidden',
                className,
            )}
            onClick={handleWishlistClick}>
            <WishlistIcon
                className={cn(
                    'h-4 w-4 transition-all duration-150 ease-linear hover:scale-125',
                    selectedItem && 'fill-black',
                    !selectedItem && 'hover:fill-black/5',
                )}
            />
        </Button>
    );
};

ProductCardFavoriteButton.displayName = 'ProductCardFavoriteButton';

export default ProductCardFavoriteButton;

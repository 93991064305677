export const identifyImageCDN = (
    src: string,
): 'contentful' | 'norce' | undefined => {
    if (isContentfulCDN(src)) {
        return 'contentful';
    } else if (isNorceCDN(src)) {
        return 'norce';
    }
};

const isContentfulCDN = (src: string) => src.includes('ctfassets');
const isNorceCDN = (src: string) =>
    /(?:cdn\.(?:nordiskagalleriet|no-ga|lannamobler|lanna)\.(?:no|se|com)|[a-zA-Z0-9-]+\.cdn\.storm\.io|noga(?:\.stage)?\.cdn-norce\.tech)/.test(
        src,
    );

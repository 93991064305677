import { envConfig } from '@/config/env';

export const formatPrice = (
    price: number,
    locale?: string,
    fallback?: string,
) => {
    if (price <= 0 && !!fallback) {
        return fallback;
    }

    const parts = formatPricePartsForSafeHydration(
        price,
        locale || envConfig.NEXT_PUBLIC_APP_LOCALE,
    );
    return parts?.map(({ value }) => value).join('');
};

export const formatPriceParts = (price: number, locale?: string) => {
    if (price <= 0) return [];
    return formatPricePartsForSafeHydration(price, locale);
};

const formatPricePartsForSafeHydration = (price: number, locale?: string) => {
    const parts = formatter(price, locale).formatToParts(price);

    if (parts[0].value === 'kr') {
        return moveCurrencyToEnd(parts);
    }

    if (locale === 'eu') {
        return mapWhitespaceAndComma(parts);
    }

    return parts;
};

const formatter = (price: number, locale?: string) => {
    // const isWhole = price % 1.0 === 0; // has no decimal part
    return getPriceFormatter(
        locale || (envConfig.NEXT_PUBLIC_APP_LOCALE ?? 'en-de'),
        /* isWhole ? 'whole' : 'fixed' */ 'fixed',
    );
};

type WholeOrFixed = 'whole' | 'fixed';

const knownPriceFormatters: { [key: string]: Intl.NumberFormat } = {};
function getPriceFormatterKey(
    locale: string,
    wholeOrFixed: WholeOrFixed,
): string {
    return `${wholeOrFixed}~${locale}`;
}

/**
 * Use "narrowSymbol" to have currencies such as "DKK" appear as "kr".
 * @url https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#narrowsymbol
 */
const NARROW_SYMBOL = 'narrowSymbol';

export function getPriceFormatter(
    locale: string,
    wholeOrFixed: WholeOrFixed,
    style: 'decimal' | 'currency' = 'currency',
): Intl.NumberFormat {
    const key = getPriceFormatterKey(locale, wholeOrFixed);
    if (key in knownPriceFormatters) {
        return knownPriceFormatters[key];
    }
    if (wholeOrFixed === 'whole') {
        const currency = localeToCurrency(locale);
        const wholeFormatter = new Intl.NumberFormat(locale, {
            style,
            currency,
            currencyDisplay: NARROW_SYMBOL,
            minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
        });
        knownPriceFormatters[key] = wholeFormatter;
        return wholeFormatter;
    }
    const currency = localeToCurrency(locale);
    const fixedFormatter = new Intl.NumberFormat(locale, {
        style,
        currency,
        currencyDisplay: NARROW_SYMBOL,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    knownPriceFormatters[key] = fixedFormatter;
    return fixedFormatter;
}

export const localeToCurrency = (locale: string): string => {
    switch (locale) {
        case 'sv-SE':
        case 'sv-se':
        case 'en-SE':
        case 'en-se':
            return 'SEK';
        case 'nb-NO':
        case 'en-NO':
        case 'nb-no':
        case 'en-no':
            return 'NOK';
        case 'dk':
        case 'da-DK':
        case 'en-DK':
            return 'DKK';
        default:
            return 'EUR';
    }
};

const mapWhitespaceAndComma = (parts: Intl.NumberFormatPart[]) => {
    return parts.map(({ type, value }) => {
        if (type === 'group') return { type, value: '\u00A0' };
        if (type === 'decimal') return { type, value: ',' };
        return { type, value };
    });
};

const moveCurrencyToEnd = (parts: Intl.NumberFormatPart[]) => {
    const [symbol, whitespace, ...rest] = parts;

    if (symbol.type !== 'currency') {
        console.error('Error: "symbol" part must be type "currency".');
        return;
    }

    if (whitespace.type !== 'literal') {
        console.error('Error: "whitespace" part must be type "literal".');
        return;
    }

    return [...mapWhitespaceAndComma(rest), whitespace, symbol];
};

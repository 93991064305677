'use client';

import Image from '@components/Image';
import { forwardRef, useContext } from 'react';

import { cn } from '@/lib/utils';

import { ProductCardContext } from './ProductCardContext';

type ProductCardHoverImageProps = {
    className?: string;
};

const ProductCardHoverImage = forwardRef<
    HTMLElement,
    ProductCardHoverImageProps
>(({ className }, ref) => {
    const {
        product,
        hoverImage,
        hideHoverImage,
        hoverImageHasLoaded,
        setHoverImageHasLoaded,
    } = useContext(ProductCardContext);

    if (!hoverImage || hideHoverImage) return null;

    return (
        <figure
            ref={ref}
            className={cn(
                'absolute inset-0 h-full w-full',
                'hidden group-hover:block',
                hoverImageHasLoaded && 'block',
                className,
            )}>
            <Image
                className="h-full w-full object-cover"
                src={hoverImage.src}
                alt={product?.title ?? ''}
                sizes="(max-width: 768px) 50vw, (max-width: 1024px) 33vw, (max-width: 1920px) 20vw, 24em"
                widths={[960, 1280]}
                onLoad={() =>
                    setHoverImageHasLoaded && setHoverImageHasLoaded(true)
                }
            />
        </figure>
    );
});

ProductCardHoverImage.displayName = 'ProductCardHoverImage';

export default ProductCardHoverImage;

import { useAuth as authContext } from '@context/authContext';

import getAnonymousRequestUid from '@/utils/getAnonymousRequestUid';
import getSearchEngineUser from '@/utils/getSearchEngineUser';

export default function getSearchEngineUserUid(): string {
    if (typeof window === 'undefined') {
        return getSearchEngineUser();
    }

    try {
        const auth = authContext();
        const userId = auth?.userId;

        return userId ?? getAnonymousRequestUid();
    } catch (error) {
        console.error('Error in getSearchEngineUserUid:', error);
        return getSearchEngineUser();
    }
}

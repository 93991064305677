'use client';

import Image from '@components/Image';
import { motion } from 'framer-motion';
import { forwardRef, useContext, useRef } from 'react';

import {
    TRANSITION_CUBIC_BEZIER,
    TRANSITION_EASE_OUT,
} from '@/config/transitions';
import { cn } from '@/lib/utils';

import { ProductCardContext } from './ProductCardContext';

type ProductCardPrimaryImageProps = {
    className?: string;
};

const MotionImage = motion(Image);

const ProductCardPrimaryImage = forwardRef<
    HTMLElement,
    ProductCardPrimaryImageProps
>(({ className, ...props }, forwardedRef) => {
    const {
        product,
        primaryImage,
        hoverImage,
        hideHoverImage,
        hoverImageHasLoaded,
        isEager,
    } = useContext(ProductCardContext);
    const ref = useRef<HTMLDivElement | null>(null);

    if (!primaryImage) return null;

    return (
        <figure
            ref={forwardedRef ?? ref}
            className={cn(
                'absolute inset-0 bg-grey-100 transition-opacity',
                hoverImageHasLoaded &&
                    !hideHoverImage &&
                    'group-hover:opacity-0',
                className,
            )}>
            <MotionImage
                className="h-full w-full object-contain px-4 mix-blend-multiply transition-opacity duration-300 ease-out"
                src={primaryImage.src}
                alt={product?.title ?? ''}
                sizes="(max-width: 768px) 50vw, (max-width: 1024px) 33vw, (max-width: 1920px) 20vw, 24em"
                widths={[640, 960]}
                whileHover={{
                    ...(!hoverImage && { scale: 1.1 }),
                    transition: {
                        ...TRANSITION_EASE_OUT,
                        duration: 0.2,
                    },
                }}
                transition={{
                    ...TRANSITION_CUBIC_BEZIER,
                    duration: 0.3,
                }}
                priority={isEager}
                {...props}
            />
        </figure>
    );
});

ProductCardPrimaryImage.displayName = 'ProductCardPrimaryImage';

export default ProductCardPrimaryImage;

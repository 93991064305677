'use client';

import { forwardRef, type ReactNode } from 'react';

import { cn } from '@/lib/utils';

import ProductCardHoverImage from './ProductCardHoverImage';
import ProductCardPrimaryImage from './ProductCardPrimaryImage';

type ProductCardImageProps = {
    className?: string;
    children?: ReactNode;
};

const ProductCardImage = forwardRef<HTMLDivElement, ProductCardImageProps>(
    ({ className, children }, ref) => (
        <div
            ref={ref}
            className={cn(
                'group relative flex aspect-portrait overflow-hidden bg-grey-100',
                className,
            )}>
            <ProductCardHoverImage />
            <ProductCardPrimaryImage />
            {children}
        </div>
    ),
);

ProductCardImage.displayName = 'ProductCardImage';

export default ProductCardImage;

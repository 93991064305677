'use client';

import Price from '@components/Price';
import { useContext } from 'react';

import { ProductCardContext } from './ProductCardContext';

const ProductCardPrice = () => {
    const { product, locale } = useContext(ProductCardContext);

    return (
        <Price
            className="mt-0.5 flex-wrap overflow-hidden"
            product={product}
            locale={locale}
        />
    );
};

ProductCardPrice.displayName = 'ProductCardPrice';

export default ProductCardPrice;

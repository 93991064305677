'use client';

import { Blockquote } from '@components/Blockquote/Blockquote';
import Link from '@components/Link';
import { Heading } from '@components/ui';
import { Button } from '@ngg/components';
import { ArrowLeftIcon } from '@ngg/icons';
import type { Dictionary } from 'types';

export default function NotFound({
    locale,
    dictionary,
}: {
    dictionary: Dictionary;
    locale?: string;
}) {
    return (
        <section className="flex max-w-[60rem] flex-col gap-y-10 lg:gap-y-20">
            <header className="flex flex-col-reverse items-start gap-y-10">
                <Heading
                    as="h1"
                    size={{
                        initial: 3,
                        lg: 2,
                    }}>
                    {dictionary?.pageNotFoundHeading ??
                        'The page could not be found'}
                </Heading>
                <Button
                    startIcon={<ArrowLeftIcon className="h-6 w-6" />}
                    className="transform-none bg-transparent p-0 text-sm font-normal tracking-wide text-black underline underline-offset-2"
                    as={Link}
                    locale={locale}
                    href="/">
                    {dictionary?.backToHomePage ?? 'Back to homepage'}
                </Button>
            </header>
            <Blockquote
                text={
                    dictionary?.pageNotFoundQuoteText ??
                    '“Decoration can be a state of mind, an unusual perception, a ritual whisper.”'
                }
                author={
                    dictionary?.pageNotFoundQuoteAuthor ?? 'Ettore Sottsass'
                }
            />
        </section>
    );
}

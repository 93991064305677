import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import { useSiteInfo } from '@context/siteInfoContext';
import type { Product, Variant } from 'types';

import { cn } from '@/lib/utils';
import { formatPrice } from '@/utils/priceUtils';

export default function Price({
    className,
    product,
    locale,
}: {
    className?: string;
    product?: Product | Variant;
    locale?: string;
}) {
    const { globalDictionary } = useSiteInfo();

    if (!product) return null;

    const isVariant = product.type == 'Variant';

    return (
        <Paragraph className={cn('flex gap-x-1', className)}>
            {product.priceType === 'priceMatch' && (
                <>
                    <span className="font-medium">
                        {product.fromPrice && globalDictionary.from}{' '}
                        {formatPrice(
                            isVariant
                                ? product.price
                                : product.presentationPrice,
                            locale,
                        )}
                    </span>
                    <span className="text-grey-300">
                        ({globalDictionary.recommendedPrice}{' '}
                        {formatPrice(
                            isVariant
                                ? (product.originalPrice ?? product.price)
                                : (product.presentationOriginal ??
                                      product.presentationPrice),
                            locale,
                        )}
                        )
                    </span>
                </>
            )}
            {product.priceType === 'campaign' && (
                <>
                    <span className="font-medium">
                        {product.fromPrice && globalDictionary.from}{' '}
                        {formatPrice(
                            isVariant
                                ? product.price
                                : product.presentationPrice,
                            locale,
                        )}
                    </span>
                    <span className="text-grey-300 line-through">
                        {formatPrice(
                            isVariant
                                ? (product.originalPrice ?? product.price)
                                : (product.presentationOriginal ??
                                      product.presentationPrice),
                            locale,
                        )}
                    </span>
                </>
            )}
            {product.priceType === 'standard' && (
                <span className="font-medium">
                    {product.fromPrice && globalDictionary.from}{' '}
                    {formatPrice(
                        isVariant ? product.price : product.presentationPrice,
                        locale,
                    )}
                </span>
            )}
        </Paragraph>
    );
}

export const campaignIsActive = (campaign: {
    campaignStart: string;
    campaignEnd: string;
}) => {
    const currentDate = new Date();
    const campaignStart = new Date(campaign.campaignStart);
    const campaignEnd = new Date(campaign.campaignEnd);
    return (
        campaignStart.getTime() <= currentDate.getTime() &&
        currentDate.getTime() <= campaignEnd.getTime()
    );
};

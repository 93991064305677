import type { ReactNode } from 'react';

import { cn } from '@/lib/utils';

export interface BlockquoteProps {
    className?: string;
    text: ReactNode;
    author?: ReactNode;
}

const Blockquote = ({ className, text, author }: BlockquoteProps) => (
    <blockquote className={cn('flex flex-col gap-y-6 text-balance', className)}>
        {text && <p className="text-xl font-bold lg:text-2xl">{text}</p>}
        {author && <cite className="not-italic">— {author}</cite>}
    </blockquote>
);

Blockquote.displayName = 'Blockquote';

export { Blockquote };

import type { StockStatus } from 'types';

import { isInStock } from './isInStock';

/**
 * Determines whether the in-stock icon should be shown for a product.
 * It should be shown if the product is in stock or if it is NOT in stock, but there is a next delivery date.
 */
export const shouldShowInStockIcon = (
    soonBackInStock: boolean,
    stockStatus?: StockStatus | null,
) => isInStock(stockStatus) || soonBackInStock;

'use client';

import { useSiteInfo } from '@context/siteInfoContext';
import type { ReactNode } from 'react';
import { useContext, useMemo } from 'react';

import { envConfig } from '@/config/env';
// import { CampaignItemFragment } from '@server/gql/graphql';
import { cn } from '@/lib/utils';
import { getMatchingCampaigns } from '@/utils/product/getMatchingCampaigns';
import { shouldShowCampaignBadge } from '@/utils/product/shouldShowCampaignBadge';

import ProductCardBadge from './ProductCardBadge';
import { ProductCardContext } from './ProductCardContext';
import ProductCardFavoriteButton from './ProductCardFavoriteButton';
import ProductCardStockStatus from './ProductCardStockStatus';

type ProductCardBadgesProps = {
    children?: ReactNode;
};

const ProductCardBadges = ({ children }: ProductCardBadgesProps) => {
    const { product, hoverImage, hideHoverImage, globalDictionary } =
        useContext(ProductCardContext);
    const { campaigns: activeCampaigns } = useSiteInfo();
    const { campaigns, news, limited } = useMemo(
        () => ({
            campaigns: getMatchingCampaigns(activeCampaigns, product?.flags),
            news: product?.flags?.includes('news'),
            limited: product?.flags?.includes('limited_edition'),
        }),
        [product, activeCampaigns],
    );

    const badgeClassNameOnHover = () => {
        // Do not animate badges on hover if there is no hover image
        if (!hoverImage || hideHoverImage) return null;

        return 'transition-opacity group-hover:opacity-0';
    };

    return (
        <>
            <ProductCardBadge
                x="start"
                y="start"
                className="px-4 py-3 pr-8 space-y-1">
                {campaigns.map((campaign) => {
                    return (
                        shouldShowCampaignBadge(
                            product?.priceType,
                            campaign,
                        ) && (
                            <p key={campaign.campaignBadge}>
                                {campaign.campaignBadge}
                            </p>
                        )
                    );
                })}
                {news && <p>{globalDictionary?.new || 'New'}</p>}
            </ProductCardBadge>

            <ProductCardBadge
                y="end"
                className={cn(
                    'px-4 py-3',
                    badgeClassNameOnHover(),
                    'flex w-full',
                    limited && 'mb-3'
                )}>
                <ProductCardStockStatus className="mt-0" />

                {product?.hasVariants && (
                    <span className="ml-auto">
                        {globalDictionary?.moreVariants}
                    </span>
                )}
            </ProductCardBadge>

            {envConfig.NEXT_PUBLIC_NORDICS_FEATURE_FLAG === 'true' && (
                <ProductCardBadge y="start" x="end">
                    <ProductCardFavoriteButton />
                </ProductCardBadge>
            )}

            {limited && (
                <ProductCardBadge className="inset-x-0 bottom-0 bg-primary text-center text-black">
                    {globalDictionary?.limitedEdition || 'NOGA Exclusives'}
                </ProductCardBadge>
            )}

            {children}
        </>
    );
};

ProductCardBadges.displayName = 'ProductCardBadges';

export default ProductCardBadges;

'use client';

import { Button } from '@ngg/components';
import { XIcon } from '@ngg/icons';
import { useContext } from 'react';

import { cn } from '@/lib/utils';

import { ProductCardContext } from './ProductCardContext';

type ProductCardRemoveProps = {
    className?: string;
};

const ProductCardRemove = ({ className, ...props }: ProductCardRemoveProps) => {
    const { onRemove } = useContext(ProductCardContext);

    if (!onRemove) return null;

    return (
        <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault(); // Don't trigger the link
                onRemove();
            }}
            className={cn(
                'absolute right-2 top-2 z-10 h-4 w-4 bg-transparent p-0 [&>span]:hidden',
                className,
            )}
            {...props}>
            <XIcon className="text-base text-black" />
        </Button>
    );
};

ProductCardRemove.displayName = 'ProductCardRemove';

export default ProductCardRemove;

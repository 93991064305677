import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

const InStockIcon = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            {...props}>
            <circle cx="6" cy="6" r="6" />
            <path
                stroke="#fff"
                strokeWidth="2"
                d="M9 3.777 5.667 8.444 3 6.111"
            />
        </svg>
    );
};

InStockIcon.displayName = 'InStockIcon';

export default InStockIcon;

import type { StockStatus } from 'types';

import { isInStock } from './isInStock';

export const isSoonBackInStock = (
    stockStatus?: StockStatus | null,
    nextDeliveryDate?: Date,
) => {
    const currentDate = new Date();
    return (
        !isInStock(stockStatus) &&
        !!nextDeliveryDate &&
        nextDeliveryDate > currentDate
    );
};

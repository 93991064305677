import { campaignIsActive } from './campaignIsActive';

export function shouldShowCampaignBadge(
    priceType: string | undefined,
    matchingCampaign: {
        campaignStart: string;
        campaignEnd: string;
        showBadgeForAllProductsInCampaign?: boolean;
    },
): boolean {
    const priceTypeIsCampaign = priceType === 'campaign';
    const showBadgeForAllProductsInCampaign =
        matchingCampaign.showBadgeForAllProductsInCampaign ?? false;
    let productIsPartOfCampaign =
        priceTypeIsCampaign || showBadgeForAllProductsInCampaign;
    productIsPartOfCampaign ??= false;
    return productIsPartOfCampaign && campaignIsActive(matchingCampaign);
}

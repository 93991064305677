import type {
    Cart,
    CartItem,
    CartItemInput,
    GetCartAddressQuery,
    Page as IPage,
} from '@server/gql/graphql';
import type { Product, Variant } from 'types/product';

import { envConfig } from '@/config/env';

declare global {
    interface Window {
        dataLayer: any;
    }
}

/* NG specification of events to send: 
https://docs.google.com/spreadsheets/d/1LF-1mvz6OTaRjQ27jIcBW8VlKL55nU3cqc2q23g71mQ/edit#gid=0 */

/* GA4 Docs, recommended parameters of events: 
https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=6817894073281423404-EU&client_type=gtag#add_to_cart */

/* Klarna checkout events docs: 
https://docs.klarna.com/klarna-checkout/in-depth-knowledge/client-side-events/#id-kzqbu0jd8nd-at-and-de */

function ensureDataLayer() {
    if (typeof window === undefined) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
}

export function gTagViewCart(cart: Cart) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    const cartItems = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.map((item, index) => ({
            item_id: item?.partNo,
            item_name: item.name,
            discount: item.isDiscounted ? item.priceOriginal - item.price : 0,
            index,
            item_brand: item.brand,
            price: item.price,
            quantity: item.quantity,
        }));

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            currency: cart?.currencyCode,
            value: cart?.totalGrossAmount,
            items: cartItems,
        },
    });
}

export function gTagAddToCart(
    incomingItems: CartItemInput[],
    cart: Cart | undefined,
) {
    if (typeof window === undefined || !incomingItems?.length) {
        return;
    }

    ensureDataLayer();

    const addedItems = incomingItems.map((item) => {
        const existingItem = cart?.items.find(
            ({ partNo }) => partNo === item.partNo,
        );
        if (!existingItem)
            return {
                price: 0,
                item_id: item.partNo,
                quantity: item.quantity,
            };
        return {
            item_id: existingItem.partNo,
            item_name: existingItem.name,
            currency: cart?.currencyCode,
            item_brand: existingItem.extras?.brand,
            price: existingItem.price,
            quantity: item.quantity,
            item_variant: existingItem.id,
        };
    });

    const totalPrice = addedItems.reduce((a, b) => a + b.price * b.quantity, 0);

    window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            currency: cart?.currencyCode,
            value: totalPrice,
            items: addedItems,
        },
    });
}

export function gTagRemoveFromCart(cartItem: CartItem, cart: Cart) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    const discount =
        cartItem?.priceOriginal && cartItem?.price
            ? cartItem.priceOriginal - cartItem.price
            : 0;

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: cart?.currencyCode,
            value: cartItem?.totalGrossAmount,
            items: [
                {
                    item_id: cartItem?.partNo,
                    item_name: cartItem?.name,
                    currency: cart?.currencyCode,
                    discount,
                    item_brand: cartItem?.brand,
                    price: cartItem?.price,
                    quantity: 0,
                },
            ],
        },
    });
}

export function gTagSelectListItem(
    product: Product | Variant,
    currency: string,
    pageData: IPage | undefined,
) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            item_list_id: pageData?.sys?.id,
            item_list_name: pageData?.title,
            items: [
                {
                    item_id: product?.id,
                    item_name: product?.title,
                    currency,
                    item_brand: product?.brand,
                    item_category: product?.['CategoryPath.Name']?.[0],
                    item_category2: product?.['CategoryPath.Name']?.[1],
                    item_category3: product?.['CategoryPath.Name']?.[2],
                    item_list_id: pageData?.sys?.id,
                    item_list_name: pageData?.title,
                    price: product?.price,
                },
            ],
        },
    });
}

export function gTagViewItem(
    variant: Variant | undefined,
    product: Product | Variant,
    currency: string,
) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    // Make sure view_item event is sent only once per product/variant view.
    // Return if view_item event for same variant exists in datalayer.
    const existingEvent = window.dataLayer.find(
        (el: any) => el?.event === 'view_item',
    );
    const foundSameId =
        existingEvent?.ecommerce?.items?.[0]?.item_id ===
        (variant ?? product)?.id;

    if (foundSameId) {
        return;
    }

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            currency,
            value: variant?.price ?? product.price,
            items: [
                {
                    item_id: variant?.id ?? product.id,
                    item_name: variant?.title ?? product.title,
                    currency,
                    item_brand: product?.brand,
                    item_category: product['CategoryPath.Name']?.[0],
                    item_category2: product['CategoryPath.Name']?.[1],
                    item_category3: product['CategoryPath.Name']?.[2],
                    price: variant?.price ?? product.price,
                    item_variant: product.id,
                },
            ],
        },
    });
}

export function gTagBeginCheckout(cart?: Cart) {
    if (typeof window === undefined || !cart) {
        return;
    }

    if (cart.items.length === 0) {
        console.info(
            'gTagBeginCheckout - Cart is empty skipping gTagBeginCheckout',
        );
        return;
    }

    ensureDataLayer();

    const existingEvent = window?.dataLayer?.find(
        (el: any) => el?.event === 'begin_checkout',
    );

    // Return if begin_checkout event exists in datalayer.
    if (existingEvent) {
        return;
    }

    const cartItems = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.map((item, index) => ({
            item_id: item?.partNo,
            item_name: item?.name,
            currency: cart?.currencyCode,
            discount:
                item?.isDiscounted && item?.priceOriginal && item?.price
                    ? item.priceOriginal - item.price
                    : 0,
            index,
            item_brand: item?.brand,
            price: item?.price,
            quantity: item?.quantity,
        }));

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: cart?.currencyCode,
            value: cart?.totalGrossAmount,
            coupon: cart?.discountCode,
            items: cartItems,
        },
    });
}

export function gTagViewItemList(
    items: (Product | Variant)[],
    currency: string,
    categoryData: IPage,
) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    const listItems = items?.map((item, index) => ({
        item_id: item.id,
        item_name: item.title,
        currency,
        index,
        item_brand: item?.brand,
        item_category: item?.['CategoryPath.Name']?.[0],
        item_category2: item?.['CategoryPath.Name']?.[1],
        item_category3: item?.['CategoryPath.Name']?.[2],
        item_list_name: categoryData?.title,
        price: item?.price,
    }));

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            item_list_id: categoryData?.sys?.id,
            item_list_name: categoryData?.title,
            items: listItems,
        },
    });
}

export function gTagAddPaymentInfo(cart?: Cart) {
    if (typeof window === undefined || !cart) {
        return;
    }

    ensureDataLayer();

    const cartItems = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.map((item, index) => ({
            item_id: item?.partNo,
            item_name: item?.name,
            discount:
                item?.isDiscounted && item?.priceOriginal && item?.price
                    ? item.priceOriginal - item.price
                    : 0,
            index,
            item_brand: item?.brand,
            price: item?.price,
            quantity: item?.quantity,
        }));

    const cartDiscountCodes =
        cart?.basketPromotions
            ?.map((promotion) => promotion.discountCode)
            .flat(2)
            .toString() ?? '';

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'add_payment_info', // Klarna 'billing_address_change'-event
        ecommerce: {
            currency: cart?.currencyCode,
            value: cart?.totalGrossAmount,
            coupon: cartDiscountCodes,
            items: cartItems,
        },
    });
}

// TODO: add shipping_tier to add_shipping_info if possible
export function gTagAddShippingInfo(cart?: Cart) {
    if (typeof window === undefined || !cart) {
        return;
    }

    ensureDataLayer();

    const cartItems = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.map((item, index) => ({
            item_id: item?.partNo,
            item_name: item?.name,
            discount:
                item?.isDiscounted && item?.priceOriginal && item?.price
                    ? item.priceOriginal - item.price
                    : 0,
            index,
            item_brand: item?.brand,
            price: item?.price,
            quantity: item?.quantity,
        }));

    const cartDiscountCodes =
        cart?.basketPromotions
            ?.map((promotion) => promotion?.discountCode)
            .flat(2)
            .toString() ?? '';

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'add_shipping_info', // Klarna 'shipping_address_change'-event.
        ecommerce: {
            currency: cart?.currencyCode,
            value: cart?.totalGrossAmount,
            coupon: cartDiscountCodes,
            shipping_tier: cart.meta?.NSHIFT_SHIPPING_TYPE ?? '',
            items: cartItems,
        },
    });
}

export function gTagPurchase({
    cart,
    cartAddress,
}: {
    cart: Cart;
    cartAddress?: GetCartAddressQuery['cartAddress'];
}) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    const cartDiscountCodes =
        cart?.basketPromotions
            ?.map((promotion) => promotion.discountCode)
            .flat(2)
            .toString() ?? '';

    const cartItems = cart?.items
        ?.filter(
            (item) =>
                item?.partNo !==
                `${envConfig.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
        )
        ?.map((item, index) => {
            const discount =
                item.isDiscounted && item.priceOriginal && item.price
                    ? item.priceOriginal - item.price
                    : 0;

            return {
                index,
                item_id: item.partNo,
                item_name: item.name,
                discount,
                item_brand: item.brand,
                price: item.price,
                quantity: item.quantity,
            };
        });

    if (window.dataLayer.length) {
        window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            transaction_id: cart.id,
            currency: cart.currencyCode,
            value: cart.totalGrossAmount,
            tax: cart.totalVat,
            shipping: cart.totalFreightInclVat,
            coupon: cartDiscountCodes,
            items: cartItems,
            enhanced_conversion_data: cartAddress && {
                email: cartAddress.customerDataSellTo.email,
                phone_number: cartAddress.customerDataSellTo.cellPhoneNumber,
                first_name: cartAddress.customerDataSellTo.firstName,
                last_name: cartAddress.customerDataSellTo.lastName,
                street: cartAddress.customerDataSellTo.street,
                city: cartAddress.customerDataSellTo.city,
                region: cartAddress.customerDataSellTo.region,
                postal_code: cartAddress.customerDataSellTo.postalCode,
                country: cartAddress.customerDataSellTo.country,
            },
        },
    });
}

export function gTagEmailRegistration(email: string) {
    if (typeof window === undefined) {
        return;
    }

    ensureDataLayer();

    window.dataLayer.push({
        event: 'email_registration',
        email,
    });
}

'use client';

import { Paragraph } from '@components/ui';
import { useContext } from 'react';

import { ProductCardContext } from './ProductCardContext';

const ProductCardBrand = () => {
    const { product } = useContext(ProductCardContext);

    if (!product?.brand) return null;

    return (
        <Paragraph
            className="mb-1 truncate leading-tight"
            size="xxsmall"
            transform="uppercase">
            {product.brand}
        </Paragraph>
    );
};

ProductCardBrand.displayName = 'ProductCardBrand';

export default ProductCardBrand;
